/*
 * @Author       : lujingwen
 * @Date         : 2024-08-25 18:50:44
 * @LastEditors  : lujingwen
 * @LastEditTime : 2024-08-26 14:37:31
 */
import React, { useState, useRef, useEffect } from "react";
import { Button, Input, Form, Toast } from "antd-mobile";
import "./index.less";
import { login_email } from "@/assets/images/index";
import { useNavigate, useLocation } from "react-router-dom";
import { loginSys, getVerifyCode } from "@/api/index";
import { removeToken } from "@/utils/token";
import { getcsrftToken, getauthToken } from "@/utils/token";

function RegisterPage() {
  const [fullName, setFullName] = useState("");
  const [time, setTime] = useState(0);
  const timer = useRef(null);
  const [form] = Form.useForm();
  const navigate = useNavigate(null);
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  const [isLoading, setIsLoading] = useState(false);

  // const isJudeIsLogin = () => {
  //   const user = localStorage.getItem("username");
  //   if (user) {
  //     navigate("/prod");
  //   }
  // };
  // useEffect(() => {
  //   isJudeIsLogin();
  // }, []);

  useEffect(() => {
    timer.current && clearInterval(timer.current);
    return () => timer.current && clearInterval(timer.current);
  }, []);

  useEffect(() => {
    if (time === 60)
      timer.current = setInterval(() => setTime((time) => --time), 1000);
    else if (time <= 0) timer.current && clearInterval(timer.current);
  }, [time]);

  const handleCountdown = async () => {
    if (time) return;
    //直接使用
    setTime(60);
    const res = await getVerifyCode();
    if (res) {
      Toast.show("get verify code success");
    }
  };

  const handleClick = async () => {
    console.log("--------");
    try {
      // removeToken();
      const values = await form.validateFields();
      setIsLoading(true);
      const res = await loginSys(values);
      console.log(
        "%c [ res ]-60",
        "font-size:13px; background:pink; color:#bf2c9f;",
        res
      );
      localStorage.setItem("username", values?.email);
      if (res?.is_new_user) {
        setIsLoading(false);
        sessionStorage.setItem("is_new_user", res?.is_new_user ? true : false);
        sessionStorage.setItem("username", values?.email);
        navigate("/guide");
      } else {
        navigate("/prod");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error");
    }
    // const values = form.getFieldsValue();
  };

  const location = useLocation();
  const pathName = location.pathname;

  //监听是否有信息
  // useEffect(() => {
  //   if (!getcsrftToken() || !getauthToken()) {
  //     navigate("/");
  //   }
  // }, [navigate, pathName]);

  return (
    <div className="register_page">
      <h1 className="welcome_text">Register/Login</h1>
      <p className="register_tips">Create your new account </p>
      <Form
        form={form}
        className="register_form"
        layout="horizontal"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            onClick={handleClick}
            shape="rounded"
            className="register_btn"
            loading={isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Email is required" },
            { pattern: emailRegex, message: "Enter a valid email" },
          ]}
          className="register_account_form_item"
        >
          <Input
            className="register_account"
            clearable
            value={fullName}
            onChange={(val) => {
              form.setFieldValue("email", val);
              setFullName(val);
            }}
            placeholder="Email"
          />
          <Form.Item className="img_email_form_item">
            <img
              className="register_email_icon"
              src={login_email}
              alt="email"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          className="get_code_form_item"
          name="verification_code"
          rules={[{ required: true, message: "Code is required" }]}
          extra={
            <div
              className={
                time !== 5 ? "send_code send_code_disabled" : "send_code"
              }
              disabled={time !== 60}
              onClick={handleCountdown}
            >
              {time ? `after ${time}s send` : "Verification code"}
            </div>
          }
        >
          <Input
            className="get_code_input"
            placeholder="Verification code"
            clearable
            autoComplete="off"
            onChange={(val) => {
              form.setFieldValue("verification_code", val);
            }}
          />
        </Form.Item>
        <div className="register_bottom_tips">
          <p className="first">By signing up you’ve agree to</p>
          <p className="seconde">Our Terms of Use And Privacy Notice </p>
        </div>
      </Form>
    </div>
  );
}

export default RegisterPage;
