const getUerName = () => {
  const username = sessionStorage.getItem("username");
  return username;
};

const getIsNewUser = () => {
  const isNewUser = sessionStorage.getItem("is_new_user");
  return JSON.parse(isNewUser);
};

export { getUerName, getIsNewUser };
