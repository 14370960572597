/*
 * @Author: lujingwen w:13420127609 please add me
 * @Date: 2024-09-01 18:00:56
 * @Last Modified by: lujingwen if have questions please add me
 * @Last Modified time: 2024-09-22 12:55:11
 */
import React, { useEffect, useRef, useState } from "react";
import "./data";
import { Slider, Toast, Grid, SpinLoading } from "antd-mobile";
import "./index.less";

import ReactAudioPlayer from "react-audio-player";
import {
  like,
  recording,
  main,
  main_active,
  mine,
  mine_active,
  unRecording,
  previous,
  next,
  start_music,
  ending_music,
} from "@/assets/images/index";
import { data } from "./data";
import { lrc } from "./lrc";
import FirstGuideBack from "./components/firstGuideBack/index";
import RecordRTC from "recordrtc";
import { getUerName, getIsNewUser } from "@/utils/user";
import { useNavigate } from "react-router-dom";
import { getProfile } from "@/api/index";
import { getauthToken } from "@/utils/token";
import ReconnectingWebSocket from "reconnecting-websocket";

function Audio() {
  const [lyric, setlyric] = useState([]); //歌词
  const [curTime, setCurTime] = useState(0); //歌词播放现在的时间
  const [duration, setDuration] = useState(0); //音乐时常 秒
  const [audioName, setAudioName] = useState("");
  const [slideValue, setSlideValue] = useState(0); //进度条百分数
  const [activeNum, setActiveNum] = useState(1);
  const [stream, setStream] = useState(null);
  const [isRecording, setIsRecording] = useState(false); //是否正在录音
  const [audioUrl, setAudioUrl] = useState("");
  const recorderRef = useRef(null); // 用于存储 RecordRTC 实例
  const navigate = useNavigate(null);
  const [touchStart, setTouchStart] = useState(0);
  const [touchY, setTouchY] = useState(0);
  const [touchTime, setTouchTime] = useState(0);
  const [lastTap, setLastTap] = useState(0);
  const [sending, setSending] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollRef = useRef(null);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const wsRef = useRef();

  //创建websock链接
  const createWebsock = async () => {
    const res = await getProfile();
    if (res?.data && Object.keys(res?.data)?.length > 0) {
      console.log(res?.data, "res?.data");
      const userId = res?.data?.profile?.user_id;
      const socketUrl = `wss://sonnd.ai/ws/chat/${userId}/?authentication_token=${getauthToken()}`;
      wsRef.current = new ReconnectingWebSocket(socketUrl);
      // wsRef.current = new WebSocket(socketUrl);
      wsRef.current.onopen = function () {
        console.log("WebSocket connection established");
        if (isFirstTime) {
          getRecordingByNextOrUp("down");
          setIsFirstTime(false);
        }
      };
      wsRef.current.onmessage = function (e) {
        const data = JSON.parse(e.data);
        if (data?.message?.audio) {
          const { audioUrl, duration, name, transcription } =
            data?.message?.audio;
          setAudioUrl(audioUrl);
          setDuration(duration);
          formatLyric(transcription);
          setCurTime(0);
          // setlyric([transcription]);
          setAudioName(name);
          setSending(false);
        }
      };

      wsRef.current.onclose = function (e) {
        console.log(e, "websocket close");
      };
      wsRef.current.onerror = function (e) {
        console.log(e, "websocket error");
      };
    }
  };

  useEffect(() => {
    if (curTime > 1 && audioUrl) {
      setSending(false);
    }
  }, [audioUrl, curTime]);

  // 检查和监听麦克风权限
  // const checkAndListenMicrophonePermission = async () => {
  //   try {
  //     // 使用 Permissions API 查询麦克风权限
  //     const permissionStatus = await navigator.permissions.query({
  //       name: "microphone",
  //     });
  //     if (permissionStatus?.state === "granted") {
  //       setisdenied(true);
  //     } else {
  //       Toast.show("Microphone access denied.");
  //       setisdenied(false);
  //     }
  //     // 监听权限变化(只有变化的时候会触发)
  //     const handlePermissionChange = () => {
  //       //granted,prompt,denied,unsupported
  //       if (permissionStatus.state === "granted") {
  //         setisdenied(true);
  //       } else {
  //         setisdenied(false);
  //         if (permissionStatus.state === "prompt") {
  //           Toast.show("Permission to access microphone is not decided yet");
  //           return;
  //         }
  //         if (permissionStatus.state === "denied") {
  //           Toast.show("Microphone access denied.");
  //           return;
  //         }
  //         if (permissionStatus.state === "unsupported") {
  //           Toast.show("Permissions API is not supported in this browser.");
  //           return;
  //         }
  //       }
  //     };

  //     permissionStatus.addEventListener("change", handlePermissionChange);

  //     // 组件卸载时移除监听器(应该放至useEffect的return里面后面有空再改写)
  //     return () => {
  //       permissionStatus.removeEventListener("change", handlePermissionChange);
  //     };
  //   } catch (error) {
  //     console.error(
  //       "Error while checking or listening microphone permission:",
  //       error
  //     );
  //     setisdenied(false);
  //   }
  // };

  //RecordRTC
  const startRTCRecording = async () => {
    try {
      const isEdge =
        navigator.userAgent.indexOf("Edge") !== -1 &&
        (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log(stream, "stream");
      if (!stream) return;
      recorderRef.current = new RecordRTC(stream, {
        type: "audio",
        mimeType: "audio/wav",
        numberOfAudioChannels: isEdge ? 1 : 2,
        checkForInactiveTracks: true,
        // 其他配置项 ...
      });

      recorderRef.current.startRecording();
      setIsRecording(true);
      setStream(stream);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const stopRTCRecording = async () => {
    try {
      setIsRecording(false);
      recorderRef.current.stopRecording(() => {
        // 这里可以处理录音数据
        let blob = recorderRef.current.getBlob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const audioDataURL = reader.result;
          const files = {
            audio: {
              optType: "chat",
              type: "audio/wav",
              dataURL: audioDataURL,
            },
          };
          if (!audioDataURL) {
            Toast.show("transfrom audio error,please try again");
            return;
          }
          setSending(true);
          const sendFilemessage = JSON.stringify({ message: files });
          wsRef.current.send(sendFilemessage);
          // 关闭麦克风。停止流的所有音轨
          if (stream) {
            stream.getTracks().forEach((track) => track.stop());
          }
          recorderRef.current = null;
          setStream(null);
        };
        reader.readAsDataURL(blob);
        // 例如，保存录音、上传服务器等
      });
    } catch (error) {
      console.log(error, "stop recording error");
    }
  };

  //取上一首或者下一首语音播放
  const getRecordingByNextOrUp = (text) => {
    try {
      if (text === "up") {
        const data = {
          audio: {
            // 手动切换下一首
            optType: "previousAudio", //previousAudio
          },
        };
        setSending(true);
        wsRef.current.send(JSON.stringify({ message: data }));
      } else {
        const data = {
          audio: {
            // 手动切换下一首
            optType: "nextAudio", //previousAudio
          },
        };
        setSending(true);
        wsRef.current.send(JSON.stringify({ message: data }));
      }
    } catch (error) {
      console.log(error, "getRecordingByNextOrUp");
    }
  };

  useEffect(() => {
    console.log(wsRef.current, "wsRef.current");
    createWebsock();
    return () => {
      // wsRef.current = null;
      clearTimeout(scrollRef.current);
    };
  }, []);

  const handleDobleChangeTime = () => {
    if (curTime > 0 && audioUrl) {
      const nowTime = curTime + 15;
      audioRef.current.audioEl.current.currentTime = nowTime;
      const percent = Math.ceil((nowTime / duration) * 100);
      setSlideValue(percent);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
    setTouchY(e.touches[0].clientY);
    setTouchTime(Date.now());
  };

  const handleTouchEnd = (e) => {
    // e.preventDefault(); //会阻止点击事件
    setLastTap(Date.now());
    const touchDuration = Date.now() - touchTime; // 长按判断
    const touchEnd = e.changedTouches[0].clientX;
    const touchEndY = e.changedTouches[0].clientY;
    const touchDelta = touchEnd - touchStart;
    const absTouchY = Math.abs(touchEndY - touchY);

    //判断上滑或者下滑了
    if (absTouchY > 10) {
      setIsScrolling(true);
      scrollRef.current = setTimeout(() => {
        setIsScrolling(false);
      }, 5000);
    }
    if (touchDuration > 500 && Math.abs(touchDelta) < 2) {
      console.log("长按");
      // 长按
      if (e?.target && audioUrl) {
        if (!isPlaying) {
          audioRef.current.audioEl.current.play();
        } else {
          audioRef.current.audioEl.current.pause();
        }
      }
    } else if (Math.abs(touchDelta) > 30) {
      // 滑动距离超过50px
      if (touchDelta > 0) {
        console.log("右滑");
        // getRecordingByNextOrUp("down");
      } else {
        console.log("左滑");
        // getRecordingByNextOrUp("up");
      }
    } else if (touchDuration < 300) {
      console.log("点击");
      // 短按，判断为点击
      // 双击间隔时间内的点击认为是双击
      if (lastTap && Date.now() - lastTap < 300) {
        console.log("双击");
        handleDobleChangeTime();
        console.log("Double tap: Forward 15 seconds");
        // audioRef.current.currentTime += 15;
      }
    }
  };
  //格式化歌词
  const formatLyric = (txt) => {
    try {
      const text = txt || lrc;
      let lines = text.split("\n"); //将歌词按行分解成数组
      let pattern = /\[\d{2}:\d{2}.(\d{3}|\d{2})\]/g; //时间格式的正则表达式(l两位数字：两位或者两位以上：两位或者三位)
      let result = []; //返回值
      //对于歌词格式不对的直接返回
      if (!/\[.+\]/.test(text)) {
        return [[0, text]];
      }
      //去掉前面格式不正确的行
      while (!pattern.test(lines[0])) {
        lines = lines.slice(1);
      }
      //遍历每一行，形成一个每行带着俩元素的数组，第一个元素是以秒为计算单位的时间，第二个元素是歌词
      for (let item of lines) {
        let time = item.match(pattern)
          ? item.match(pattern)
          : item?.split("]")?.[1]; //存前面的时间段
        let value = item.replace(pattern, ""); //存后面的歌词
        if (time && time?.length > 0) {
          for (let item1 of time) {
            let t = item1.slice(1, -1).split(":"); //取出时间，换算成数组
            if (value != "") {
              result.push([parseInt(t[0], 10) * 60 + parseFloat(t[1]), value]);
            }
          }
        }
      }
      //按照第一个元素--时间--排序
      result.sort(function (a, b) {
        return a[0] - b[0];
      });
      result.unshift([0.0, ""]);
      setlyric(result);
      console.log(result, "result");
    } catch (error) {
      console.log(error, "error");
    }
  };

  //给歌词打上颜色
  const setColors = () => {
    for (let i = 0; i < lyric.length; i++) {
      if (curTime >= lyric[i][0]) {
        for (let j = 0; j < lyric.length; j++) {
          document.querySelectorAll(".song_lists_ul li")[j].style.color =
            "rgba(23, 65, 43, 0.5)";
          document.querySelectorAll(".song_lists_ul li")[j].style.fontSize =
            "16px";
        }
        if (i >= 0) {
          document.querySelectorAll(".song_lists_ul li")[i].style.color =
            "#17412b";
          document.querySelectorAll(".song_lists_ul li")[i].style.fontSize =
            "25px";
          document.querySelectorAll(".song_lists_ul li")[i].style.lineHeight =
            "40px";
          const dom = document.querySelectorAll(".song_lists_ul li")[i];
          dom.scrollIntoView({ block: "center" });
        }
      }
    }
  };

  useEffect(() => {
    if (lyric && lyric?.length > 0 && !isScrolling) {
      setColors();
    }
  }, [curTime, isScrolling, lyric]);

  const audioRef = useRef(null);

  //渲染歌词
  const renderlrc = () => {
    if (lyric?.length > 0) {
      return (
        <ul className="song_lists_ul">
          {lyric?.map((item, index) => (
            <li key={index}>{item[1]}</li>
          ))}
        </ul>
      );
    }
  };

  //监听播放时间
  const handleListen = (val) => {
    setCurTime(val);
    const percent = Math.ceil((val / duration) * 100);
    setSlideValue(percent);
  };

  const handleChangeProgress = (value) => {
    const time = duration * (value / 100)?.toFixed(1);
    setCurTime(time);
    setSlideValue(value);
    audioRef.current.audioEl.current.currentTime = time;
    audioRef.current.audioEl.current.play();
  };

  const [touchNum, setTouchNum] = useState(1);
  const [showGuide, setShowGuide] = useState(false);
  //初次进来引导页面
  const handleTouchGuideStart = (e) => {
    console.log(touchNum, "touchNum");
    setTouchNum((state) => state + 1);
  };
  useEffect(() => {
    if (touchNum > 4) {
      setShowGuide(false);
      sessionStorage.setItem("is_new_user", false);
    }
  }, [touchNum]);
  useEffect(() => {
    const isNewUser = getIsNewUser();
    const isUserName = getUerName() ? true : false;
    if (isUserName && isNewUser) {
      setShowGuide(true);
    } else {
      setShowGuide(false);
    }
  }, []);

  //处理错误
  const handleError = (e) => {
    console.log(e, "播放错误");
  };

  //录音
  const handleStartOrStopRecording = () => {
    if (isPlaying) {
      audioRef.current.audioEl.current.pause();
    }
    if (isRecording) {
      stopRTCRecording();
    } else {
      startRTCRecording();
    }
  };

  //监听播放
  const handlePlay = (e) => {
    if (e.type === "play") {
      setIsPlaying(true);
    }
  };

  //监听暂停
  const handlePause = (e) => {
    if (e.type === "pause") {
      setIsPlaying(false);
    }
  };

  const handleChangePlaying = () => {
    if (isPlaying) {
      audioRef.current.audioEl.current.pause();
    } else {
      audioRef.current.audioEl.current.play();
    }
  };

  return (
    <div
      className="audio_page"
      onContextMenu={(e) => e.preventDefault()}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div className="song_top">
        <div className="name">{audioName}</div>
        <div className="top_gradient">{sending && <SpinLoading />}</div>
      </div>
      <div className="lry_list">{renderlrc()}</div>
      <div className="time_show">
        <span className="first_time"></span>
        <span className="second_time"></span>
      </div>
      <div className="audio_auth">
        <img
          onClick={handleStartOrStopRecording}
          src={isRecording ? recording : unRecording}
          className="voice_icon"
          alt=""
        />
        <div className="progress_slide">
          <Slider
            className="progress_slide"
            onChange={handleChangeProgress}
            value={slideValue}
          />
        </div>

        <img src={like} className="like_icon" alt="" />
      </div>
      <Grid columns={3} gap={8} className="tool_box">
        <Grid.Item>
          <div
            className="previous"
            onClick={() => getRecordingByNextOrUp("up")}
          >
            <img src={previous} alt="previous" />
          </div>
        </Grid.Item>
        <Grid.Item>
          <div className="start_stop_recording">
            <img
              src={isPlaying ? ending_music : start_music}
              alt="previous"
              onClick={handleChangePlaying}
            />
          </div>
        </Grid.Item>
        <Grid.Item>
          <div
            className="next_previous"
            onClick={() => getRecordingByNextOrUp("down")}
          >
            <img src={next} alt="next" />
          </div>
        </Grid.Item>
      </Grid>
      <div className="audio_bottom">
        <img
          src={activeNum === 1 ? main_active : main}
          alt="main_pic"
          onClick={() => {
            setActiveNum(1);
          }}
        />
        <img
          src={activeNum === 1 ? mine : mine_active}
          alt="mine_pic"
          onClick={() => {
            navigate("/mine");
            setActiveNum(2);
          }}
        />
      </div>
      <div>
        <ReactAudioPlayer
          onPlay={handlePlay}
          onPause={handlePause}
          listenInterval={500}
          src={audioUrl}
          autoPlay={true}
          ref={audioRef}
          onError={handleError}
          //   currentTime
          //   time
          // style={{ display: 'block!impoartant' }}
          // listenInterval={handleUpdateTime}
          onListen={handleListen}
        />
      </div>
      {showGuide && (
        <FirstGuideBack
          handleTouchStart={handleTouchGuideStart}
          type={touchNum}
        />
      )}
    </div>
  );
}

export default Audio;
