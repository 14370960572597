/*
 * @Author       : lujingwen
 * @Date         : 2024-08-25 18:50:44
 * @LastEditors  : lujingwen
 * @LastEditTime : 2024-08-26 13:53:17
 */
import React, { useState, useRef, useEffect } from "react";
import { Button, Input, Checkbox, Form } from "antd-mobile";
// import { ArrowLeftOutline } from "antd-mobile-icons";
import "./index.less";
import { LeftOutline } from "antd-mobile-icons";
import { back_icon, login_email } from "@/assets/images/index";

function LoginPage() {
  const [fullName, setFullName] = useState("");
  // const [countdown, setCountdown] = useState(5); //倒计时
  const [time, setTime] = useState(0);
  const timer = useRef(null);
  const [form] = Form.useForm();

  useEffect(() => {
    timer.current && clearInterval(timer.current);
    return () => timer.current && clearInterval(timer.current);
  }, []);

  useEffect(() => {
    if (time === 60)
      timer.current = setInterval(() => setTime((time) => --time), 1000);
    else if (time <= 0) timer.current && clearInterval(timer.current);
  }, [time]);

  const handleCountdown = () => {
    if (time) return;
    //直接使用
    setTime(60);
    // let count = countdown;
    // const timer = setInterval(() => {
    //   count--;
    //   setCountdown(count);
    //   if (count === 0) {
    //     clearInterval(timer);
    //   }
    // }, 1000);
  };

  const getVoicehandleSubmit = () => {
    // 实现登录逻辑
  };
  let recognition = new (window.SpeechRecognition ||
    window.webkitSpeechRecognition ||
    window.mozSpeechRecognition ||
    window.msSpeechRecognition)();
  const getVoice = () => {
    recognition.lang = "zh-CN";
    recognition.start();
  };

  recognition.onresult = function (event) {
    const transcript = event.results[0][0].transcript;
  };

  const handleClick = () => {
    console.log("--------");
    const values = form.getFieldsValue();
    console.log(
      "%c [ values ]-65",
      "font-size:13px; background:pink; color:#bf2c9f;",
      values
    );
  };

  return (
    <div className="login_page">
      <div className="login_back">
        <div className="clear"></div>
      </div>
      <div className="back_box">
        <img className="back_icon" src={back_icon} alt="back" />
      </div>
      <h1 className="welcome_text">Welcome back</h1>
      <p className="login_tips">Login to your account</p>
      <Form
        form={form}
        className="login_form"
        layout="horizontal"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            onClick={handleClick}
            className="login_btn"
          >
            Login
          </Button>
        }
      >
        <Form.Item
          name="loginName"
          rules={[{ required: true, message: "email cannot be empty" }]}
          className="login_account_form_item"
        >
          <Input
            className="login_account"
            clearable
            value={fullName}
            onChange={(val) => {
              form.setFieldValue("loginName", val);
              setFullName(val);
            }}
            placeholder="Email"
            // prefix={<img alt='email' src={login_email} />}
          />
          <img className="login_email_icon" src={login_email} alt="email" />
        </Form.Item>
        <div></div>
        <Form.Item
          className="get_code"
          name="loginCode"
          extra={
            <div
              className={
                time !== 5 ? "send_code send_code_disabled" : "send_code"
              }
              disabled={time !== 60}
              onClick={handleCountdown}
            >
              {time ? `after ${time}s send` : "Verification code"}
            </div>
          }
        >
          <Input
            className="get_code_input"
            placeholder="Verification code"
            clearable
            onChange={(val) => {
              form.setFieldValue("loginCode", val);
            }}
          />
        </Form.Item>
        <Form.Item className="check_form_item" name="loginCheck">
          <Checkbox
            className="login_check_box"
            onChange={(val) => {
              form.setFieldValue("loginCheck", val);
            }}
          >
            Remember me
          </Checkbox>
          <span className="forgot_text">Forgot Password?</span>
        </Form.Item>
      </Form>
      <div className="signup-link">
        <span className="first_text"> Don’t have an account?</span>
        <span className="second_text">Sign up</span>
      </div>
    </div>
  );
}

export default LoginPage;
