// 路由鉴权
// 1. 判断token是否存在
// 2. 如果存在 直接正常渲染
// 3. 如果不存在 重定向到登录路由

import { Navigate } from "react-router-dom";
import { getcsrftToken } from "@/utils/token";

function AuthRoute({ children }) {
  const tokenStr = getcsrftToken();
  if (tokenStr) {
    return <>{children}</>;
  } else {
    return <Navigate to="/register" replace />;
  }
}

export default AuthRoute;
