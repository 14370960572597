import React, { useRef } from "react";
import { TextArea, Toast, Button, SafeArea } from "antd-mobile";
import { back_icon, upload } from "@/assets/images/index";
import "./index.less";

function UploadPage() {
  const handler = useRef();

  //上传文件
  const handleChangeFile = (e) => {
    const file = e.target.files && e.target.files[0];
    handler.current = Toast.show({
      icon: "loading",
      content: "uploading",
      duration: 0,
    });
    //   handler.close()
  };
  //返回
  const handleback = () => {};
  return (
    <div className="upload_page">
      <div className="top_bar">
        <div className="back_box" onClick={handleback}>
          <img className="top_bar_back" src={back_icon} alt="" />
        </div>
        <span className="top_bar_text">Audio authoring</span>
      </div>
      <div className="content_box">
        <TextArea
          className="upload_area"
          placeholder="Please enter text content..."
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
        <div className="upload_file">
          <div className="audio_file_title">
            <span className="first">Upload audio</span>
            <span className="second">
              （Provide at least 30 seconds of audio）
            </span>
          </div>
          <div className="upload_btn">
            <img src={upload} alt="upload" />
            <input
              className="upload_input"
              type="file"
              onChange={handleChangeFile}
            />
          </div>
        </div>
      </div>
      <div className="bottom">
        <Button className="sumbit_btn">submit</Button>
      </div>
      <div style={{ background: "#fff" }}>
        <SafeArea position="bottom" />
      </div>
    </div>
  );
}

export default UploadPage;
