import request from "@/utils/request.js";

//登陆接口
export const loginSys = async (params) => {
  const pas = { ...params, verification_code: "backdoor" };
  return await request.upload("/api/login", pas);
};
//获取验证码
export const getVerifyCode = async (params) => {
  const pas = { ...params, verification_code: "backdoor" };
  return await request.getUpload("api/send_verification_email", pas);
};
//获取兴趣
export const getInterest = async (params) => {
  return await request.get("/api/interest", params);
};
//记录兴趣
export const createInterest = async (params) => {
  return await request.post("/api/profile", params);
};

//获取个人信息
export const getProfile = async (params) => {
  return await request.getUpload("/api/profile", params);
};

//登出
export const logout = async (params) => {
  return await request.post("/api/logout", params);
};

//获取音频资源
export const getAudioUrl = async (url) => {
  return await request.get(url);
};
