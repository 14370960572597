import React, { useEffect, useState } from "react";
import { back_icon, mine_back } from "@/assets/images/index";
import "./index.less";
import { Avatar, Button, SafeArea } from "antd-mobile";
import {
  guide_1,
  guide_2,
  guide_3,
  guide_4,
  guide_5,
  guide_6,
  guide_7,
  guide_8,
  guide_9,
  guide_10,
  guide_11,
  guide_12,
} from "@/assets/images/index";
import { useNavigate } from "react-router-dom";
import { getProfile, logout } from "@/api/index";
import { removeToken } from "@/utils/token";

const Mine = () => {
  const [mineData, setMineData] = useState({});
  const [interestList, setInterList] = useState([]);
  const navigate = useNavigate();
  const [logoutLoading, setLogoutLoading] = useState(false);
  const gudeList = [
    { id: 1, imageUrl: guide_1, text: "ART" },
    { id: 2, imageUrl: guide_2, text: "commerce" },
    { id: 3, imageUrl: guide_3, text: "comedy" },
    { id: 4, imageUrl: guide_4, text: "education    " },
    { id: 5, imageUrl: guide_5, text: "novel" },
    { id: 6, imageUrl: guide_6, text: "government" },
    { id: 7, imageUrl: guide_7, text: "history" },
    { id: 8, imageUrl: guide_8, text: "Health & Fitness" },
    { id: 9, imageUrl: guide_9, text: "Children and family" },
    { id: 10, imageUrl: guide_10, text: "fallow" },
    { id: 11, imageUrl: guide_11, text: "MUSICIANS" },
    { id: 12, imageUrl: guide_12, text: "news" },
    { id: 13, imageUrl: guide_12, text: "news" },
    { id: 14, imageUrl: guide_12, text: "news" },
  ];
  //返回
  const handleback = () => {
    console.log("返回");
    navigate(-1);
  };

  //获取个人信息
  const getProfileMsg = async () => {
    const res = await getProfile();
    if (res?.data && Object.keys(res?.data)?.length > 0) {
      setMineData(res?.data?.profile);
      const interests = res?.data?.interests?.map((item) => {
        const idx = gudeList.findIndex((itm) => itm.id === item.weight);
        return { ...item, picture_url: gudeList?.[idx]?.imageUrl };
      });

      setInterList(interests);
    }
  };

  //退出登陆
  const dologout = async () => {
    try {
      setLogoutLoading(true);
      const res = await logout();
      if (res) {
        setLogoutLoading(false);
        navigate("/register");
        removeToken();
        localStorage.clear();
      }
    } catch (error) {
      setLogoutLoading(false);
    }
  };
  useEffect(() => {
    getProfileMsg();
  }, []);
  return (
    <div className="mine">
      <div className="top_bar">
        <div className="back_box" onClick={handleback}>
          <img className="top_bar_back" src={back_icon} alt="back" />
        </div>
      </div>
      <div className="main_box">
        <div className="personal_msg_box">
          {/* <img src={mine_back} alt="mine_back" className="mine_back_top" /> */}
          <div className="personal_msg">
            <Avatar className="avatar" src="" />
            <div className="msg_first">{mineData?.username || "-"}</div>
            <div className="msg_second">{`Email：${
              mineData?.username || "-"
            }`}</div>
          </div>
        </div>
        <div className="tag_box">
          <div className="tag_content">
            <div className="tag_top_bar">
              <span className="left_title">Interest tag</span>
            </div>
            <div className="mine_tag_list">
              {interestList?.map((item) => (
                <div className="guide_item" key={item?.id}>
                  <img
                    className="guide_item_image"
                    src={item.picture_url}
                    alt={item.picture_url}
                  />
                  <div className="guide_item_text">{item.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="sumbit_box">
        <Button
          className="sumbit_btn"
          onClick={dologout}
          loading={logoutLoading}
        >
          log out
        </Button>
      </div>
      <div style={{ background: "#fff" }}>
        <SafeArea position="bottom" />
      </div>
    </div>
  );
};

export default Mine;
