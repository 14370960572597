import React, { useEffect } from "react";
import "./index.less";
import App from "@/App";

function Home() {
  return (
    <div className="home">
      <App />
    </div>
  );
}

export default Home;
