import React, { useEffect, useState } from "react";
import { Checkbox, List, Button, Toast, SafeArea } from "antd-mobile";
import {
  guide_1,
  guide_2,
  guide_3,
  guide_4,
  guide_5,
  guide_6,
  guide_7,
  guide_8,
  guide_9,
  guide_10,
  guide_11,
  guide_12,
  arrow_down,
} from "@/assets/images/index";
import "./index.less";
import { useNavigate } from "react-router-dom";
import { getInterest, createInterest } from "@/api/index";

function Guide() {
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(true);
  const [guideList, setguideList] = useState([]);
  const [selectGuideList, setSelectGuideList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function adjustViewHeight() {
    const realVh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--realVh", `${realVh}px`);
  }

  window.addEventListener("resize", adjustViewHeight);
  adjustViewHeight(); // 初始设置

  const gudeList = [
    { id: 1, imageUrl: guide_1, text: "ART" },
    { id: 2, imageUrl: guide_2, text: "commerce" },
    { id: 3, imageUrl: guide_3, text: "comedy" },
    { id: 4, imageUrl: guide_4, text: "education    " },
    { id: 5, imageUrl: guide_5, text: "novel" },
    { id: 6, imageUrl: guide_6, text: "government" },
    { id: 7, imageUrl: guide_7, text: "history" },
    { id: 8, imageUrl: guide_8, text: "Health & Fitness" },
    { id: 9, imageUrl: guide_9, text: "Children and family" },
    { id: 10, imageUrl: guide_10, text: "fallow" },
    { id: 11, imageUrl: guide_11, text: "MUSICIANS" },
    { id: 12, imageUrl: guide_12, text: "news" },
    { id: 13, imageUrl: guide_12, text: "news" },
    { id: 14, imageUrl: guide_12, text: "news" },
  ];

  const getGuideList = async () => {
    try {
      const res = await getInterest({});
      if (res?.data) {
        const interests = res?.data?.interests?.map((item) => {
          const idx = gudeList.findIndex((itm) => itm.id === item.weight);
          return { ...item, picture_url: gudeList?.[idx]?.imageUrl };
        });
        // const interests = res?.data?.interests?.sort(
        //   (a, b) => a.weight - b.weight
        // );
        setguideList(interests);
      }
    } catch (error) {
      if (error) {
        Toast.show("Network is error,please go to login page again");
      }
    }
  };

  function unique(arr, key) {
    const res = new Map();
    return arr.filter((item) => !res.has(item[key]) && res.set(item[key], 1));
  }

  const changeCheckboxItem = (val, item) => {
    if (val) {
      setSelectGuideList((state) =>
        unique([...state, { id: item.id, name: item.name }], "id")
      );
    } else {
      const newState = [...selectGuideList];
      const idx = newState.findIndex((itm) => itm.id === item.id);
      newState.splice(idx, 1);
      setSelectGuideList(newState);
    }
  };

  useEffect(() => {
    getGuideList();
  }, []);

  const handleSumbit = async () => {
    try {
      const params = {
        profile: {
          username: sessionStorage.getItem("username"),
        },
        interests: selectGuideList,
      };
      setIsLoading(true);
      const res = await createInterest(params);
      if (res) {
        setIsLoading(false);
        navigate("/prod");
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <div className="guide_page">
      <div className="skip_btn">skip</div>
      <div className="first_title">Select the tag of interest</div>
      <div className="second_title_box">
        <div className="second_title">
          According to your interests and preferences, recommend your favorite
          content accurately
        </div>
      </div>
      <div
        className="guide_check_list"
        onTouchMove={() => {
          setIsShow(false);
        }}
      >
        <Checkbox.Group>
          <List>
            {guideList.map((item) => (
              <div className="guide_item">
                <img
                  className="guide_item_image"
                  src={item.picture_url}
                  alt={item.name}
                />
                <div className="guide_item_text">{item.name}</div>

                <Checkbox
                  className="guide_item_check"
                  onChange={(val) => changeCheckboxItem(val, item)}
                ></Checkbox>
              </div>
            ))}
          </List>
        </Checkbox.Group>
      </div>
      {isShow && (
        <div className="swiper_tips">
          <div className="top_text">Slide down to see more</div>
          <img src={arrow_down} alt="arrow_down" />
        </div>
      )}
      <div className="sumbit_box">
        <Button
          className="sumbit_btn"
          onClick={handleSumbit}
          loading={isLoading}
        >
          submit
        </Button>
      </div>
      <div style={{ background: "#fff" }}>
        <SafeArea position="bottom" />
      </div>
    </div>
  );
}

export default Guide;
