import React from "react";
import "./index.less";
import finger_one from "@/assets/images/finger/finger_one.png";
import finger_two from "@/assets/images/finger/finger_two.png";
import finger_three from "@/assets/images/finger/finger_three.png";
import left from "@/assets/images/finger/left.png";
import right from "@/assets/images/finger/right.png";

function FirstGuideBack(props) {
  const { type, handleTouchStart } = props;
  const renderBack = () => {
    if (type === 1) {
      return (
        <div className="content">
          <div className="_text">
            Press and hold to stop audio and get haptic
            <br /> feedback
          </div>
          <div className="img_box">
            <img className="finger_one" src={finger_one} alt="" />
          </div>
        </div>
      );
    } else if (type === 2) {
      return (
        <div className="content">
          <div className="_text">Swipe left to skip to the next audio</div>
          <div className="img_box_two">
            <img src={left} alt="left" className="left_img" />
            <img className="finger_two" src={finger_two} alt="" />
          </div>
        </div>
      );
    } else if (type === 3) {
      return (
        <div className="content">
          <div className="_text">
            Swipe right to return to the previous audio
          </div>
          <div className="img_box_three">
            <img className="finger_three" src={finger_three} alt="" />
            <img src={right} alt="left" className="right_img" />
          </div>
        </div>
      );
    } else {
      return (
        <div className="content">
          <div className="_text">Double click to fast forward 15 seconds</div>
          <div className="img_box">
            <img className="_img" src={finger_one} alt="" />
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className="fist_guide_back"
      // onTouchStart={handleTouchStart}
      onClick={handleTouchStart}
    >
      {renderBack()}
    </div>
  );
}

export default FirstGuideBack;
