// 判断参数是否为空 的方法
const valueIsNull = (val) => (val === 0 ? true : !!val);

// 清空对象中为空、null、undefind的属性 的方法
export const cleanObjectParams = (params) => {
  const obj = { ...params };
  for (const key in obj) {
    if (typeof obj[key] !== "boolean") {
      if (valueIsNull(obj[key]) === false) {
        delete obj[key];
      }
      if (obj[key] === "null") {
        delete obj[key];
      }
    }
  }
  return obj;
};

export const cleanFormDataParams = (params) => {
  const from = new FormData();
  for (const key in params) {
    if (
      valueIsNull(params[key]) !== false ||
      typeof params[key] === "boolean"
    ) {
      from.append(key, params[key]);
    }
  }
  return from;
};
