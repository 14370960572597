import { createBrowserRouter } from "react-router-dom";
import Home from "@/pages/home/index";
import Mine from "@/pages/mine/index";
import Login from "@/pages/login/index";
import Register from "@/pages/register/index";
import Guide from "@/pages/guide/index";
import UploadPage from "@/pages/uploadPage/index";
import Prod from "@/pages/prod/index";
//可以用 path:"*" 写个 404页面，我暂时不需要
import AuthRoute from "@/components/authRoute/index";
import Main from "@/layout/main/index";

const router = createBrowserRouter([
  { path: "/", element: <Main /> },
  { path: "/register", element: <Register /> },

  {
    path: "/mine",
    element: (
      <AuthRoute>
        <Mine />
      </AuthRoute>
    ),
  },
  { path: "/login", element: <Login /> },
  { path: "/register", element: <Register /> },
  {
    path: "/guide",
    element: (
      <AuthRoute>
        <Guide />
      </AuthRoute>
    ),
  },
  {
    path: "/upload",
    element: (
      <AuthRoute>
        <UploadPage />
      </AuthRoute>
    ),
  },
  {
    path: "/prod",
    element: (
      <AuthRoute>
        <Prod />
      </AuthRoute>
    ),
  },
]);

export default router;
