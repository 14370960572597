/*
 * @Author       : lujingwen
 * @Date         : 2024-09-08 18:51:02
 * @LastEditors  : lujingwen
 * @LastEditTime : 2024-09-09 10:35:54
 */
import { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  redirect,
  // useLocation,
  useNavigate,
  Navigate,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import router from "./routes";
import "@/assets/style/global.less";
import VConsole from "vconsole";
import { getcsrftToken, getauthToken } from "@/utils/token";
import Register from "@/pages/register/index";
import { getProfile } from "@/api/index";
import "./index.css";

function App() {
  const vConsole = new VConsole();

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
