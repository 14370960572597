import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Main() {
  console.log("这是新代码9-16");
  const navigate = useNavigate(null);
  const isJudeIsLogin = () => {
    const user = localStorage.getItem("username");
    if (user) {
      navigate("/prod");
    } else {
      navigate("/register");
    }
  };
  useEffect(() => {
    isJudeIsLogin();
  }, []);
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default Main;
