import Cookies from "js-cookie";

export const AUTHTOKEN = "authentication_token";

export const CSRFTOKEN = "csrftoken";

//获取token
export const getcsrftToken = () => {
  if (Cookies.get(CSRFTOKEN)) {
    return Cookies.get(CSRFTOKEN);
  }
};

export const getauthToken = () => {
  if (Cookies.get(AUTHTOKEN)) {
    return Cookies.get(AUTHTOKEN);
  }
};

export const setcsrftToken = (val) => {
  Cookies.set(CSRFTOKEN, val);
};

export const setAuthToken = (val) => {
  Cookies.set(AUTHTOKEN, val);
};

export const removeToken = () => {
  Cookies.remove(AUTHTOKEN);
  Cookies.remove(CSRFTOKEN);
};
