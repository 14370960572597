import axios from "axios";
import {
  getcsrftToken,
  getauthToken,
  removeToken,
  AUTHTOKEN,
  CSRFTOKEN,
} from "@/utils/token.js";
import { cleanObjectParams, cleanFormDataParams } from "@/utils/common.js";

class Request {
  service;
  constructor() {
    if (!Request.instance) {
      this.service = axios.create({
        // baseURL: "/api",
        responseType: "json",
        timeout: 100000, // 设置统一的超时时长
      });
      // 请求拦截
      this.service.interceptors.request.use((config) => {
        console.log(config, "config");
        const headers = {
          // "Content-Type": "application/json",
          [AUTHTOKEN]: getauthToken(),
          [CSRFTOKEN]: getcsrftToken(),
          "X-CSRFToken": getcsrftToken(),
        };
        config.url = config.url || "";
        config.headers = Object.assign(headers, config?.headers || {});
        console.log(headers, config.headers, "config.headers");
        return config;
      });
      this.service.interceptors.response.use(
        // 响应拦截
        (response) => {
          console.log(
            "%c [ response ]-36",
            "font-size:13px; background:pink; color:#bf2c9f;",
            response
          );
          if (response?.status === 403) {
            window.location.href = "/";
          }
          try {
            const res = response?.data;
            if (typeof res === "string")
              return JSON.parse(res) ? JSON.parse(res) : {};
            else return res;
          } catch (error) {
            console.log(
              "%c [ error ]-50",
              "font-size:13px; background:pink; color:#bf2c9f;",
              error
            );
            return Promise.reject(error);
          }
        },
        // 错误拦截
        (error) => {
          if (error?.response?.status === 403) {
            removeToken();
            localStorage.clear();
            // window.location.href = "/register";
            setTimeout(() => {
              window.location.href = "/register";
            }, 100);
          }
          console.log(error, "error");
          Promise.reject(error);
        }
      );

      Request.instance = this;
    } else return Request.instance;
  }

  // 将对象转为 url searchParams 字符串 的方法
  object2UrlSearchParams = (obj) => {
    const arr = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const str = `${key}=${obj[key]}`;
        arr.push(str);
      }
    }
    return arr.join("&");
  };

  get(url, params, config = {}) {
    const clearParams = cleanObjectParams(params);
    const queryParams = this.object2UrlSearchParams(clearParams);
    if (queryParams) url += `?${queryParams}`;
    return this.service.get(url, config);
  }

  getSpecial(url, params, config = {}) {
    const key = Object.keys(params);
    let path = "";
    key.forEach((itm) => {
      path = path + `/${params[itm]}`;
    });
    const newUrl = (url += path);
    return this.service.get(newUrl, config);
  }

  post(url, params, config = {}) {
    const clearParams = cleanObjectParams(params);
    return this.service.post(url, clearParams, config);
  }

  specialPost(url, params, config = {}) {
    const clearParams = cleanObjectParams(params);
    return this.service.post(url, clearParams, config);
  }
  upload(url, params, config = {}) {
    const clearFormData = cleanFormDataParams(params);
    const defHeader = { "Content-Type": "multipart/form-data" };
    config.headers = Object.assign(defHeader, config?.headers || {});
    console.log("321321321321");
    return this.service.post(url, clearFormData, config);
  }
  getUpload(url, params, config = {}) {
    const clearFormData = cleanFormDataParams(params);
    const defHeader = { "Content-Type": "multipart/form-data" };
    config.headers = Object.assign(defHeader, config?.headers || {});
    return this.service.get(url, clearFormData, config);
  }
}

export default new Request();
